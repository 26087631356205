/**
 * @export 内容运营
 * @author 林良伟
 * @Created 2022/7/21.
 */
export default {
    hotContentOperateSetUp: {
        // Hot 内容运营设置
        path: '/hot-content-operate/set-up',
        name: 'hotContentOperateSetUp',
        component: () => import('@/pages/modules/hot-content-operate/set-up/index.vue'),
    },
    hotContentOperateDisplayedList: {
        // 运营位已展示列表
        path: '/hot-content-operate/displayed-list',
        name: 'hotContentOperateDisplayedList',
        component: () => import('@/pages/modules/hot-content-operate/displayed-list/index.vue'),
    },
    CommunityOperationPosition: {
        // 社区运营位推荐
        path: '/hot-content-operate/community-operation',
        name: 'CommunityOperationPosition',
        component: () => import('@/pages/modules/hot-content-operate/community-operation/index.vue'),
    },
    sortPointTest: {
        // 排序分测试
        path: '/hot-content-operate/sort-point-test',
        name: 'sortPointTest',
        component: () => import('@/pages/modules/hot-content-operate/sort-point-test/index.vue'),
    },
    operationBitPool: {
        // 运营位池
        path: '/hot-content-operate/operation-bit-pool',
        name: 'operationBitPool',
        component: () => import('@/pages/modules/hot-content-operate/operation-bit-pool/index.vue'),
    },
    communityConfig: {
        // 社区配置
        path: '/hot-content-operate/community-config',
        name: 'communityConfig',
        component: () => import('@/pages/modules/hot-content-operate/community-config/index.vue'),
    }
};
