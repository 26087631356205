/**
 * @export IM管理
 * @author 方俊杰
 * @Created 2024/10/24.
 */
export default {
    userChatRecords: {
        // 用户聊天记录
        name: 'userChatRecords',
        path: '/im-manage/userChatRecords',
        component: () => import('@/pages/modules/im-manage/user-chat-records/index.vue'),
    }
}
